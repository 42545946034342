import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Betting from "../../../components/body/pages/bill-payments/betting"

const BettingPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/bill-payments/betting/"}
      title="Betting Payments | Top Up Your Account Now | Kuda"
      description="The Kuda app makes it quick & easy to top up your betting account. Whether you're betting on football or racing, Kuda has you covered."
    />
    <Betting />
  </Layout>
)

export default BettingPage
